<template>
  <div class="edit_active">
    <div class="head">
      <div
        class="item"
        :class="headTabActive == item.id ? 'active' : ''"
        @click="clickItem(item)"
        v-for="item in tabHead"
        :key="item.id"
      >
        {{ item.value }}
      </div>
    </div>
    <div class="context">
      <!-- 资讯详情 -->
      <eventDetails v-if="headTabActive === 0" :activeId="activeId"></eventDetails>
      <!-- 报名表单 -->
      <RegistrationForm v-else :activeId="activeId"></RegistrationForm>
    </div>
  </div>
</template>
<script>
import eventDetails from './eventDetails.vue'
import RegistrationForm from './RegistrationForm.vue'

export default {
  name: 'EditActive',
  components: { eventDetails, RegistrationForm },
  data() {
    //这里存放数据
    return {
      tabHead: [
        {
          value: '活动详情',
          id: 0
        },
        {
          value: '报名表单',
          id: 1
        }
      ],
      // tab类名控制
      headTabActive: null,
      // 点击编辑进来的 那一项id
      activeId: null,
      id: null
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  created() {
    this.id = this.$route.query.id
    if (this.id) {
      this.activeId = this.$route.query.id
    }
    if (this.$route.query.headTabActive) {
      this.headTabActive = this.$route.query.headTabActive * 1
    }
  },
  mounted() {},
  //方法集合
  methods: {
    // 点击头部table栏
    clickItem(item) {
      this.headTabActive = item.id
    }
  }
}
</script>
<style scoped lang="less">
.edit_active {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .head {
    // width: 100%;
    height: 70px;
    box-sizing: border-box;
    // margin: 0 72px 0 22px;
    padding-left: 20px;
    display: flex;
    border-bottom: 1px solid #efefef;
    .item {
      color: #999999;
      margin-right: 50px;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 20px;
    }

    .active {
      color: #4e93fb;
      font-size: 20px;
      border-bottom: 2px solid #4e93fb;
      border-radius: 1px;
    }
  }
  .context {
    width: 100%;
    height: calc(100% - 70px);
  }
}
</style>
