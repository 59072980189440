<template>
  <div class="RegistrationForm">
    <div class="RegistrationForm_flex">
      <div class="RegistrationForm_flex_left">
        <div class="RegistrationForm_flex_left_title">
          <span>基础题型</span>
        </div>
        <!-- 左侧添加问题按钮 -->
        <div class="flex_buttom">
          <el-button
            v-for="item in typeBtnListOne"
            :key="item.codeId"
            :icon="getTypeBtnIcon(item.codeId)"
            @click="addProblemItemHasType(item.codeId)"
            >{{ item.codeName }}</el-button
          >
        </div>
        <div
          class="RegistrationForm_flex_left_title"
          style="margin-top: 50px"
          v-if="typeBtnListTwo.length > 0"
        >
          <span>个人信息</span>
        </div>
        <div class="flex_buttom">
          <el-button
            v-for="item in typeBtnListTwo"
            :key="item.codeId"
            :icon="getTypeBtnIcon(item.codeId)"
            @click="addProblemItemHasType(item.codeId)"
            >{{ item.codeName }}</el-button
          >
        </div>
      </div>
      <!-- 右边 -->
      <div class="RegistrationForm_flex_right" v-if="topicList.length > 0">
        <!-- 中间问题列表 -->
        <div class="content_box">
          <!-- 问题item -->
          <div
            class="problem_item"
            v-for="(i1, i2) in topicList"
            :key="i2"
            :class="i2 === activeIndex ? 'active_problem_item' : ''"
            @click="problemItemClick(i1, i2)"
          >
            <!-- 标题行 -->
            <div class="title_row">
              {{ i2 + 1 + '.' + i1.questionsTitle }}
              <span class="color2" v-if="i1.isRequired">*</span>
              <div class="move_btn_list">
                <span class="top_bottom" v-if="i2 != 0" @click="moveUp(i1, i2)">上移</span>
                <!-- <img
                  style="width: 20px; height: 20px"
                  src="../../../assets/images/top3.png"
                  alt=""
                /> -->
                <span
                  class="top_bottom"
                  @click="shiftDown(i1, i2)"
                  v-if="i2 + 1 != topicList.length"
                  >下移</span
                >
                <!-- <img
                  style="width: 20px; height: 20px"
                  src="../../../assets/images/buttom3.png"
                  alt=""
                /> -->
              </div>
            </div>
            <!-- 提示行 -->
            <div class="tips_row">{{ i1.questionsExplain }}</div>
            <!-- 选项内容 -->
            <div class="options_box">
              <!-- 单选题 -->
              <div v-if="i1.type === 1 || i1.type === 11" class="options_content">
                <el-radio
                  disabled
                  v-for="(ii1, ii2) in i1.activityFormOptionList"
                  :key="ii2"
                  :label="ii1.optionName"
                ></el-radio>
              </div>
              <!-- 文件上传 -->
              <div v-else-if="i1.type === 2" class="options_content">
                <div class="upload_box">
                  <div class="btn_txt">+上传附件</div>
                  <div class="color1">单个文件大小不超过30MB</div>
                </div>
                <div class="color1">
                  <span v-if="!i1.fileFormatList || i1.fileFormatList.length < 1"
                    >文件格式不限</span
                  >
                  <span v-else>
                    {{ '文件格式' + arrToString(i1.fileFormatList) }}
                  </span>
                  ,文件个数{{ i1.isLimit ? i1.limitMinimum + '~' + i1.limitMax + '个' : '不限' }}
                </div>
              </div>
              <!-- 多选题 -->
              <div v-else-if="i1.type === 3" class="options_content">
                <el-checkbox
                  disabled
                  v-for="(ii1, ii2) in i1.activityFormOptionList"
                  :key="ii2"
                  :label="ii1.optionName"
                ></el-checkbox>
              </div>
              <!-- 图片上传 -->
              <div v-else-if="i1.type === 4" class="options_content">
                <div class="upload_box">
                  <div class="btn_txt">+上传图片</div>
                  <div class="color1">单个图片大小不超过2MB</div>
                </div>
                <div class="color1">
                  <span v-if="!i1.fileFormatList || i1.fileFormatList.length < 1"
                    >文件格式不限</span
                  >
                  <span v-else>
                    {{ '文件格式' + arrToString(i1.fileFormatList) }}
                  </span>
                  ,图片个数{{ i1.isLimit ? i1.limitMinimum + '~' + i1.limitMax + '个' : '不限' }}
                </div>
              </div>
              <!-- 下拉框 -->
              <div class="select_row" v-else-if="isSelectType(i1.type)">
                <el-select v-model="i1.hint" :placeholder="i1.hint" disabled>
                  <el-option
                    v-for="(ii1, ii2) in i1.activityFormOptionList"
                    :key="ii2"
                    :label="ii1.optionName"
                    :value="ii1.optionName"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 填空题、搜索选择 -->
              <div v-else-if="isFillType(i1.type)" class="select_row">
                <el-input :placeholder="i1.hint" disabled></el-input>
              </div>
              <!-- 选择时间 -->
              <!-- :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30'
                }" -->
              <div v-else-if="i1.type === 13" class="select_row">
                <el-time-select :placeholder="i1.hint" readonly></el-time-select>
              </div>
              <!-- 选择日期 -->
              <!-- :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30'
                }" -->
              <div v-else-if="i1.type === 12" class="select_row">
                <el-date-picker type="date" :placeholder="i1.hint" readonly></el-date-picker>
              </div>
              <!-- 按钮 -->
              <div class="i_box">
                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                  <i class="el-icon-delete" @click.stop="removeProblemItem(i2)"></i>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="复制" placement="top">
                  <i class="el-icon-s-order" @click.stop="addProblemItem(i1, i2)"></i>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <!-- 编辑选中的问题item -->
        <div class="right_type_box">
          <div class="type_title_row">
            <div class="square_box"></div>
            {{ getTypeTxt(activeItem.type) }}
          </div>
          <div class="item_title">
            标题
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content" style="width: 250px">
                标题用于告诉填写者应该在该字段中输入什么样的内容。通常是一个词语或词组，也可以是一个问题。
              </div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <!--  @change="titleTxtChange" -->
          <div class="row">
            <el-input placeholder="请输入标题" v-model="activeItem.questionsTitle"></el-input>
          </div>
          <div class="item_title">
            问题说明
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content" style="width: 250px">
                提示属性用于指定对该模块进行一些附加说明，一般用来指导填写者输入。
              </div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <div class="row">
            <el-input placeholder="请输入问题说明" v-model="activeItem.questionsExplain"></el-input>
          </div>
          <!-- 指示文字 -->
          <div v-if="getTypeBol(5)">
            <div class="item_title">
              指示文字
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content" style="width: 250px">
                  指示文字用于引导用户在输入框内应该输入的内容。
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <div class="row">
              <el-input placeholder="请输入指示文字" v-model="activeItem.hint"></el-input>
            </div>
          </div>
          <!-- 选项内容 -->
          <div class="item_title" v-if="getTypeBol(1)">
            选项内容
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content" style="width: 250px">
                为选择组件提供具体内容（可以添加“其他”选项，用作选项不符时的自定义填写），可以自定义编辑和增删。
              </div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <div class="option_list" v-if="getTypeBol(1)">
            <div
              class="option_input"
              v-for="(i1, i2) in activeItem.activityFormOptionList"
              :key="i2"
            >
              <el-input placeholder="请输入指示文字" v-model="i1.optionName"></el-input>
              <!-- 图标 -->
              <i class="el-icon-delete" @click="removeOptionInput(i2)"></i>
            </div>
            <el-button
              icon="el-icon-circle-plus"
              @click="addOptionInput(activeItem.activityFormOptionList.length)"
              >添加新选项</el-button
            >
          </div>
          <!-- 附件上传 -->
          <div v-if="getTypeBol(2)">
            <div class="item_title">
              文件格式
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content" style="width: 250px">
                  选择文件格式后，填写者需选择对应的文件格式才可上传。
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <el-checkbox-group v-model="activeItem.fileFormatList">
              <el-checkbox label="pdf"></el-checkbox>
              <el-checkbox label="excel"></el-checkbox>
              <el-checkbox label="word"></el-checkbox>
            </el-checkbox-group>
            <div class="row">
              <el-checkbox v-model="activeItem.isLimit">
                文件个数
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" style="width: 250px">
                    选择文件个数后，填写者只能上传对应的文件个数。
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-checkbox>
            </div>
            <div class="input_row" v-show="activeItem.isLimit">
              <el-input
                placeholder="请输入"
                v-model.number="activeItem.limitMinimum"
                oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
              ></el-input>
              <span class="center_span">~</span>
              <el-input
                placeholder="请输入"
                v-model.number="activeItem.limitMax"
                oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
              ></el-input>
            </div>
          </div>
          <!-- 图片上传 -->
          <div v-if="getTypeBol(3)">
            <div class="item_title">
              图片格式
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content" style="width: 250px">
                  选择图片格式后，填写者选择对应的图片格式才可上传。
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <el-checkbox-group v-model="activeItem.fileFormatList">
              <el-checkbox label="png"></el-checkbox>
              <el-checkbox label="jpg"></el-checkbox>
              <el-checkbox label="svg"></el-checkbox>
            </el-checkbox-group>
            <div class="row">
              <el-checkbox v-model="activeItem.isLimit">
                图片个数
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" style="width: 250px">
                    选择图片个数后，填写者只能上传对应的图片个数。
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-checkbox>
            </div>
            <div class="input_row" v-show="activeItem.isLimit">
              <el-input
                placeholder="请输入"
                v-model.number="activeItem.limitMinimum"
                oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
              ></el-input>
              <span class="center_span">~</span>
              <el-input
                placeholder="请输入"
                v-model.number="activeItem.limitMax"
                oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
              ></el-input>
            </div>
          </div>
          <!-- 搜索内容 -->
          <div v-if="getTypeBol(6)">
            <div class="item_title">
              搜索内容
              <!-- <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content" style="width: 250px">
                  选择图片格式后，填写者选择对应的图片格式才可上传。
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip> -->
            </div>
            <el-radio-group v-model="activeItem.searchContent">
              <el-radio
                v-for="(i1, i2) in searchContentList"
                :key="i2"
                :label="i1.codeName"
              ></el-radio>
            </el-radio-group>
          </div>
          <!-- 必填项 -->
          <div class="row">
            <el-checkbox v-model="activeItem.isRequired">
              这是个必填项
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content" style="width: 250px">
                  选择必填后，填写者不填写将无法提交表单。
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </el-checkbox>
          </div>
          <!-- 短信验证 -->
          <div v-if="getTypeBol(7)">
            <div class="row">
              <el-checkbox v-model="activeItem.isOne">
                短信验证
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" style="width: 250px">
                    选择短语验证后，填写者需要展示输入手机号对应验证码。
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-checkbox>
            </div>
          </div>
          <!-- 日期段 -->
          <div v-if="getTypeBol(8)">
            <div class="row">
              <el-checkbox v-model="activeItem.isOne">
                日期段
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" style="width: 250px">
                    选择日期段后，填写者需要输入起始日期。
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-checkbox>
            </div>
          </div>
          <!-- 时间段 -->
          <div v-if="getTypeBol(9)">
            <div class="row">
              <el-checkbox v-model="activeItem.isOne">
                时间段
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" style="width: 250px">
                    选择时间段后，填写者需要输入起始时间。
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-checkbox>
            </div>
          </div>
          <!-- 字数限制 -->
          <div v-if="getTypeBol(4)">
            <div class="row">
              <el-checkbox v-model="activeItem.isLimit">
                字数限制
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" style="width: 250px">
                    选择字数个数后，填写者只能上传对应的字数。
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-checkbox>
            </div>
            <div class="input_row" v-if="activeItem.isLimit">
              <el-input
                placeholder="请输入"
                v-model="activeItem.limitMinimum"
                oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
              ></el-input>
              <span class="center_span">~</span>
              <el-input
                placeholder="请输入"
                v-model="activeItem.limitMax"
                oninput="value=value.replace(/^0+(\d)|[^\d]+/g,'')"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot_btn_list">
      <!-- <el-button class="btn1">保存并发布</el-button> -->
      <el-button class="btn2" @click="saveBtn">保存</el-button>
      <el-button class="btn3" @click="backBtn">返回上一级</el-button>
    </div>
  </div>
</template>
<script>
import { getCode } from '@/api/common'
import {
  selectActivityFormById,
  insertActivityForm,
  updateActivityForm,
  registrationRecordsNumber
} from '@/api/activecenter'
export default {
  props: {
    activeId: {}
  },
  data() {
    return {
      requestCount: 0, // 节流阀
      hasUser: null, // 是否有已报名用户
      topicList: [], //题目集合
      activeIndex: null, // 选中的问题index
      activeItem: {}, // 选中的问题item
      searchContentList: [], // 搜索内容
      typeBtnList: [], // 添加问题item按钮列表
      typeBtnListOne: [], // 基础题型 item按钮列表
      typeBtnListTwo: [] // 个人信息 item按钮列表
    }
  },
  filters: {},
  computed: {
    //  是否是下拉选择类型
    isSelectType() {
      return function (type) {
        const arr = [5, 14, 17, 18]
        const idx = arr.indexOf(type)
        if (idx !== -1) {
          return true
        }
      }
    },
    // 是否是填空题类型
    isFillType() {
      return function (type) {
        const arr = [6, 7, 8, 9, 10, 15, 16, 19]
        const idx = arr.indexOf(type)
        if (idx !== -1) {
          return true
        }
      }
    },
    // 数组转字符串
    arrToString() {
      return function (arr) {
        const txt = arr.join(',')
        return txt
      }
    },
    // 获取左侧按钮图标
    getTypeBtnIcon() {
      return function (type) {
        switch (type) {
          case 1:
            return 'el-icon-circle-check'
          case 2:
            return 'el-icon-folder-opened'
          case 3:
            return 'el-icon-folder-checked'
          case 4:
            return 'el-icon-picture-outline'
          case 5:
            return 'el-icon-circle-check'
          case 6:
            return 'el-icon-tickets'
          case 7:
            return 'el-icon-search'
          case 8:
            return 'el-icon-user'
          case 9:
            return 'el-icon-phone-outline'
          case 10:
            return 'el-icon-message'
          case 11:
            return 'el-icon-male'
          case 12:
            return 'el-icon-date'
          case 13:
            return 'el-icon-time'
          case 14:
            return 'el-icon-s-custom'
          case 15:
            return 'el-icon-menu'
          case 16:
            return 'el-icon-school'
          case 17:
            return 'el-icon-film'
          case 18:
            return 'el-icon-office-building'
          case 19:
            return 'el-icon-s-cooperation'
          // case 20:
          //   return 'el-icon-tickets'
          // case 21:
          //   return 'el-icon-search'
        }
      }
    },
    // 控制右边设置选中问题要展示的内容
    getTypeBol() {
      return function (type) {
        //展示的内容 type: 1.选项内容；2.文件格式和文件个数；3.图片格式和图片个数；4.字数限制；5.指示文字；6.搜索内容；7.短信验证码；8.日期段；9.时间段
        const activeType = this.activeItem.type
        let bol = false
        if (type === 1) {
          // 14, --->学历是否需要可自定义设置选项内容？
          const arr = [1, 3, 5, 11, 14]
          const idx = arr.indexOf(activeType)
          bol = idx !== -1 ? true : false
          return bol
        } else if (type === 2) {
          bol = activeType === 2 ? true : false
          return bol
        } else if (type === 3) {
          bol = activeType === 4 ? true : false
          return bol
        } else if (type === 4) {
          const arr = [6, 8, 9, 10, 15, 16]
          const idx = arr.indexOf(activeType)
          bol = idx !== -1 ? true : false
          return bol
        } else if (type === 5) {
          const arr = [5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19]
          const idx = arr.indexOf(activeType)
          bol = idx !== -1 ? true : false
          return bol
        } else if (type === 6) {
          bol = activeType === 7 ? true : false
          return bol
        } else if (type === 7) {
          bol = activeType === 9 ? true : false
          return bol
        } else if (type === 8) {
          bol = activeType === 12 ? true : false
          return bol
        } else if (type === 9) {
          bol = activeType === 13 ? true : false
          return bol
        }
      }
    },
    // 获取右侧问题类型文字
    getTypeTxt() {
      // 问题类型;1是单选，2附件上传，3多项选择，4图片上传，5下拉选择，6文字填空
      return function (type) {
        switch (type) {
          case 1:
            return '单项选择'
          case 2:
            return '附件上传'
          case 3:
            return '多项选择'
          case 4:
            return '图片上传'
          case 5:
            return '下拉选择'
          case 6:
            return '文字填空'
          case 7:
            return '搜索选择'
          case 8:
            return '姓名'
          case 9:
            return '联系电话'
          case 10:
            return '邮箱'
          case 11:
            return '性别'
          case 12:
            return '日期'
          case 13:
            return '时间'
          case 14:
            return '学历'
          case 15:
            return '专业'
          case 16:
            return '院校'
          case 17:
            return '行业'
          case 18:
            return '城市'
          case 19:
            return '商会'
        }
      }
    }
  },
  created() {
    this.getUserCount()
    this.getTypeBtnList()
    this.getSearchContent()
    if (this.activeId) {
      this.search()
    }
  },
  watch: {
    activeIndex(newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.topicList.length > 0) {
          this.activeItem = this.topicList[this.activeIndex]
        } else {
          this.activeItem = {}
        }
      }
    }
  },
  methods: {
    // 获取已报名用户统计
    async getUserCount() {
      const { data: res } = await registrationRecordsNumber({ activityId: this.activeId })
      if (res.resultCode === 200) {
        this.hasUser = res.data
      }
    },

    // 获取添加问题item按钮列表
    async getTypeBtnList() {
      const { data: res } = await getCode({ code: '017' })
      if (res.resultCode === 200) {
        // this.typeBtnList = res.data
        res.data.forEach((el, idx) => {
          if (idx < 7) {
            // 基础题型
            this.typeBtnListOne.push(el)
          } else {
            // 个人信息
            this.typeBtnListTwo.push(el)
          }
        })
      }
    },
    // 获取搜索内容搜索内容列表
    async getSearchContent() {
      const { data: res } = await getCode({ code: '018' })
      if (res.resultCode === 200) {
        this.searchContentList = res.data
      }
    },
    // 根据活动id查询活动表单信息
    async search() {
      const { data: res } = await selectActivityFormById({ id: this.activeId })
      if (res.resultCode === 200) {
        if (res.data && res.data.length > 0) {
          // 如果数据不为空
          res.data.forEach((item) => {
            if (!item.activityFormOptionList) {
              // 如果选项列表为null时转成空数组
              item.activityFormOptionList = []
            }
            if (!item.fileFormatList) {
              // 如果文件格式为null时转成空数组
              item.fileFormatList = []
            } else {
              // 如果设置了文件格式
              // excel-->'xls','xlsx'; word-->'doc','docx'
              const index = item.fileFormatList.indexOf('xls')
              const i2 = item.fileFormatList.indexOf('doc')
              if (index !== -1) {
                // 勾选了excel
                item.fileFormatList.splice(index, 2)
                item.fileFormatList.push('excel')
              }
              if (i2 !== -1) {
                // 勾选了word
                item.fileFormatList.splice(index, 2)
                item.fileFormatList.push('word')
              }
            }
          })
          this.topicList = res.data
          console.log(this.topicList)
        } else {
          // 数据为空时
          for (let i = 0; i < 5; i++) {
            let obj = {
              activityFormOptionList: [
                {
                  activityFormId: null,
                  optionName: ''
                }
              ],
              searchContent: '', // 搜索内容
              isLimit: false, // 是否限制文件个数
              limitMinimum: null, // 限制文件个数的最小值
              limitMax: null, // 限制文件个数的最大值
              fileFormatList: [], // 上传文件、图片格式
              activityId: this.activeId,
              hint: '', // 问题填写提示
              id: null, // 问题id
              isRequired: 0, // 是否必填;0代表非必填，1是代表必填
              questionsExplain: '', // 问题说明
              questionsTitle: '', // 标题，问题名字例如姓名
              sort: 0, // 标题，问题名字例如姓名
              type: 1 // 问题类型;1是单选，2附件上传，3多项选择，4图片上传，5下拉选择，6文字填空，7搜索选择
            }
            if (i === 0) {
              obj.questionsTitle = '姓名'
              obj.type = 6
              obj.hint = '请输入' + obj.questionsTitle
              obj.isLimit = true
              obj.limitMinimum = 2
              obj.limitMax = 16
            } else if (i === 1) {
              obj.questionsTitle = '性别'
              obj.hint = '请选择' + obj.questionsTitle
              obj.activityFormOptionList = [
                {
                  activityFormId: null,
                  optionName: '先生'
                },
                {
                  activityFormId: null,
                  optionName: '女士'
                }
              ]
              obj.type = 1
            } else if (i === 2) {
              obj.questionsTitle = '所在商会'
              obj.type = 19
              obj.hint = '请输入' + obj.questionsTitle
              obj.searchContent = '商会'
            } else if (i === 3) {
              obj.questionsTitle = '商会职务'
              obj.type = 6
              obj.hint = '请输入' + obj.questionsTitle
            } else if (i === 4) {
              obj.questionsTitle = '手机号'
              obj.type = 9
              obj.hint = '请输入' + obj.questionsTitle
              obj.isLimit = true
              obj.limitMinimum = 11
              obj.limitMax = 11
            }
            this.topicList.push(obj)
          }
        }
        this.activeIndex = 0
      } else {
        this.$message.error('获取表单信息失败!')
      }
    },
    // 左侧添加问题item
    addProblemItemHasType(type) {
      const obj = {
        activityFormOptionList: [
          {
            activityFormId: null,
            optionName: '选项1'
          }
        ],
        searchContent: '', // 搜索内容
        isOne: false, // 是否为日期段、是否短信验证
        isLimit: false, // 是否限制文件个数
        limitMinimum: null, // 限制文件个数的最小值
        limitMax: null, // 限制文件个数的最大值
        fileFormatList: [], // 上传文件、图片格式
        activityId: this.activeId,
        hint: '', // 问题填写提示 输入框提示语
        id: null, // 问题id
        isRequired: false, // 是否必填
        questionsExplain: '', // 问题说明
        questionsTitle: this.getTypeTxt(type), // 标题，问题名字例如姓名
        sort: 0, // 标题，问题名字例如姓名
        type: type // 问题类型;1是单选，2附件上传，3多项选择，4图片上传，5下拉选择，6文字填空，7搜索选择
      }

      const arr = [6, 7, 8, 9, 10, 15, 16, 19]
      const idx = arr.indexOf(type)
      const arr1 = [1, 3, 5, 11, 12, 13, 14, 17, 18]
      const idx1 = arr1.indexOf(type)
      if (idx1 !== -1) {
        obj.hint = '请选择' + obj.questionsTitle
      } else if (idx !== -1) {
        obj.hint = '请输入' + obj.questionsTitle
      } else if (type === 2) {
        obj.hint = '请上传附件'
      } else if (type === 4) {
        obj.hint = '请上传图片'
      }
      if (type === 9 || type === 10) {
        // 电话和邮箱默认设置限制长度
        obj.isLimit = true
        if (type === 9) {
          obj.limitMinimum = 11
          obj.limitMax = 11
        } else {
          obj.limitMinimum = 6
          obj.limitMax = 30
        }
      }
      if (type === 14) {
        // 设置学历下拉框选项
        obj.activityFormOptionList = [
          {
            activityFormId: null,
            optionName: '专科'
          },
          {
            activityFormId: null,
            optionName: '本科'
          },
          {
            activityFormId: null,
            optionName: '研究生'
          },
          {
            activityFormId: null,
            optionName: '其他'
          }
        ]
      } else if (type === 11) {
        // 设置性别选项
        obj.activityFormOptionList = [
          {
            activityFormId: null,
            optionName: '先生'
          },
          {
            activityFormId: null,
            optionName: '女士'
          }
        ]
      } else if (type === 19) {
        obj.searchContent = '商会'
      }

      this.topicList.push(obj)
      if (!this.activeIndex) {
        this.activeIndex = 0
      }
    },
    // 点击问题item
    problemItemClick(i1, i2) {
      console.log(i1)
      if (i2 !== this.activeIndex) {
        this.activeIndex = i2
        this.activeItem = i1 // this.topicList[i2]
      }
    },
    // 删除问题item
    removeProblemItem(i2) {
      if (this.activeIndex === i2) {
        // 删除选中状态的item时
        this.activeIndex = 0
      }
      this.topicList.splice(i2, 1)
      if (this.topicList.length > 0) {
        this.activeItem = this.topicList[0]
      } else {
        this.activeItem = {}
      }
    },
    // 添加问题item 复制
    addProblemItem(i1, i2) {
      // 深拷贝
      const obj = JSON.parse(JSON.stringify(i1))
      obj.id = null
      if (obj.activityFormOptionList && obj.activityFormOptionList.length > 0) {
        obj.activityFormOptionList.map((el) => {
          el.activityFormId = null
        })
      }
      // this.topicList.push(obj) // 加到最后一行
      this.topicList.splice(i2, 0, obj) // 加到选中的item下一个
    },
    // 上移
    moveUp(item, index) {
      this.topicList.splice(index, 1)
      this.topicList.splice(index - 1, 0, item)
      // this.topicList.forEach((item, index) => {
      //   item.sort = index
      // })
    },
    // 下移
    shiftDown(item, index) {
      this.topicList.splice(index, 1)
      this.topicList.splice(index + 1, 0, item)
      // this.topicList.forEach((item, index) => {
      //   item.sort = index
      // })
    },
    // 标题输入框值变化
    // titleTxtChange(val) {
    //   if (!this.activeItem.questionsExplain) this.activeItem.hint = val
    // },
    // 删除选项输入框item
    removeOptionInput(i2) {
      if (this.activeItem.activityFormOptionList.length > 1) {
        this.activeItem.activityFormOptionList.splice(i2, 1)
      } else {
        this.$message.warning('请至少设置一个选项~')
      }
    },
    // 添加选项输入框item
    addOptionInput(index) {
      this.activeItem.activityFormOptionList.push({
        activityFormId: null,
        optionName: '选项' + (index + 1)
      })
    },
    // 重置节流阀
    resetRequestCount() {
      this.requestCount++
      setTimeout(() => {
        this.requestCount = 0
      }, 1500)
    },
    // 保存按钮
    saveBtn() {
      if (this.hasUser) {
        // 如果已经有用户报名
        this.$confirm('此活动已有用户报名，修改可能影响用户数据，是否确认修改？')
          .then((_) => {
            this.submit()
          })
          .catch((_) => {
            return
          })
      } else {
        this.submit()
      }
    },
    // 提交
    async submit() {
      if (this.requestCount === 0) {
        this.resetRequestCount()
        this.topicList.forEach((item, index) => {
          item.sort = index
          // 处理选中的文件格式
          const i2 = item.fileFormatList.indexOf('excel')
          const ii2 = item.fileFormatList.indexOf('word')
          if (i2 !== -1) {
            // 勾选了excel
            const arr = ['xls', 'xlsx']
            item.fileFormatList.splice(i2, 1)
            item.fileFormatList = [...item.fileFormatList, ...arr]
          }
          if (ii2 !== -1) {
            // 勾选了word
            const arr = ['doc', 'docx']
            item.fileFormatList.splice(ii2, 1)
            item.fileFormatList = [...item.fileFormatList, ...arr]
          }
        })
        const bol = this.topicList[0].id ? true : false
        const fn = bol ? updateActivityForm : insertActivityForm
        // const txt = bol ? '编辑' : '新增'
        const { data: res } = await fn(this.topicList)
        if (res.resultCode === 200) {
          this.$message.success('保存成功!')
          this.backBtn()
        } else {
          this.$message.error('保存失败!')
        }
      }
    },
    // 返回按钮
    backBtn() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped lang="less">
.RegistrationForm {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding-top: 16px;
  box-sizing: border-box;
  background-color: #f6f7fb;
  height: 100%;
  overflow-y: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .el-radio ::v-deep .el-radio__label {
    line-height: 40px;
  }
  .el-checkbox ::v-deep .el-checkbox__label {
    line-height: 40px;
  }
  .RegistrationForm_flex {
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
    background-color: transparent;
    // margin-bottom: 10px;
    .RegistrationForm_flex_left,
    .content_box {
      height: 100%;
      overflow-y: auto;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 3px;
        height: 9px;
      }
      /* 滚动条里面的滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        border-radius: 20px;
        transition: background-color 0.3s;
      }
    }
    .RegistrationForm_flex_left {
      width: 300px;
      height: 100%;
      background-color: #fff;
      margin-right: 40px;
      padding: 14px 24px 150px 24px;
      box-sizing: border-box;
      border-radius: 4px;
      .RegistrationForm_flex_left_title {
        font-size: 16px;
        color: #333;
        padding: 10px 0 20px 0;
      }
      .flex_buttom {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .el-button {
          width: 120px;
          font-size: 16px;
          margin: 0;
          margin-bottom: 10px;
          color: #595f66;
          border: 1px solid #e6eaf0;
        }
        ::v-deep .el-button {
          display: flex;
          background-color: #f9fafc;
          padding: 12px;
        }
        ::v-deep .el-button:hover {
          color: #182339;
        }
        ::v-deep .el-button:focus {
          color: #182339;
          background: #f5f8ff;
          border: 1px solid #e6eaf0;
        }
      }
    }
    .RegistrationForm_flex_right {
      flex: 1;
      display: flex;
      .content_box {
        width: 80%;
        display: flex;
        flex-flow: column;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;
        // height: 72vh;

        .active_problem_item {
          background-color: #f3f7ff !important;
        }
        .problem_item:hover {
          background-color: #f9fafc;
        }
        .problem_item:hover,
        .active_problem_item {
          .i_box {
            display: block !important;
          }
        }

        .problem_item {
          cursor: pointer;
          padding: 10px;
          border-radius: 8px;
          margin-bottom: 10px;
        }
        .title_row {
          display: flex;
          width: 100%;
          font-weight: 600;
          font-size: 18px;
          line-height: 36px;
          color: #000;
          .move_btn_list {
            margin-left: auto;
            min-width: 100px;
            display: flex;
            .top_bottom {
              cursor: pointer;
              font-size: 16px;
              font-weight: 500;
              color: #4e93fb;
              padding: 0 5px;
            }
          }
        }
        .tips_row {
          width: 100%;
          font-size: 14px;
          line-height: 40px;
          color: #d4d4d4;
          margin-bottom: 10px;
        }
        .options_box {
          font-size: 14px;
          width: 100%;
          display: flex;
          flex-flow: column;
          position: relative;
          .i_box {
            display: none;
            position: absolute;
            bottom: 0;
            right: 0;
            flex: 1;
            padding: 0 10px;
            margin-left: 20px;
            box-sizing: border-box;
            line-height: 40px;
            background-color: #fff;
            border-radius: 4px;
            i {
              font-size: 20px;
              color: #767e89;
              margin: 0 5px;
            }
            .el-icon-delete:hover {
              color: #182339;
            }
          }
          .select_row {
            display: flex;
            ::v-deep .el-select,
            .el-input {
              width: 80%;
            }
          }
          .options_content {
            display: flex;
            flex-flow: column;
            .upload_box {
              display: flex;
              flex-flow: column;
              align-items: center;
              justify-content: center;
              width: 250px;
              height: 130px;
              background-color: #fff;
              border: 1px dashed #e6eaf0;
              border-radius: 4px;
              margin-bottom: 10px;
              .btn_txt {
                font-size: 14px;
                color: #000;
                line-height: 40px;
              }
            }
          }
        }
      }
      .right_type_box {
        min-width: 300px;
        margin-left: 40px;
        padding: 14px 24px 150px 24px;
        box-sizing: border-box;
        background-color: #fff;
        .el-checkbox-group,
        .el-radio-group {
          line-height: 40px;
          padding-left: 20px;
          margin-bottom: 10px;
        }
        .el-radio ::v-deep .el-radio__label {
          line-height: 40px;
          color: #000 !important;
        }
        .el-checkbox ::v-deep .el-checkbox__label {
          line-height: 40px;
          color: #000 !important;
        }
        .el-icon-warning-outline {
          font-size: 14px;
          color: #d4d4d4;
        }
        .row {
          display: flex;
          line-height: 40px;
          margin-bottom: 10px;
        }
        .type_title_row {
          display: flex;
          align-items: center;
          font-size: 22px;
          color: #595f66;
          margin-bottom: 20px;
          .square_box {
            width: 20px;
            height: 20px;
            background-color: #d9d9d9;
            margin-right: 10px;
          }
        }
        .item_title {
          font-weight: 600;
          font-size: 18px;
          line-height: 40px;
          color: #000;
        }
        .option_list {
          display: flex;
          flex-flow: column;
          width: 100%;
          margin-bottom: 10px;
          .option_input {
            width: 100%;
            margin-bottom: 10px;
            position: relative;
            .el-icon-delete {
              cursor: pointer;
              position: absolute;
              top: 12px;
              right: 10px;
              font-size: 16px;
              color: #d4d9e1;
            }
            .el-icon-delete:hover {
              color: #182339;
            }
          }
        }
        .input_row {
          display: flex;
          margin-bottom: 10px;
          ::v-deep .el-input {
            width: 80px;
          }
          .center_span {
            padding: 0 5px;
            line-height: 40px;
          }
        }
      }
    }
    .bg_img_box {
      flex: 1;
      height: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      img {
        width: 210px;
        height: auto;
      }
    }
  }
  .foot_btn_list {
    width: 100%;
    text-align: center;
    padding: 14px 0;
    margin-top: auto;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    .el-button {
      width: 120px;
    }
    ::v-deep .btn1 {
      color: #fff;
      border-color: #ff7d18;
      background-color: #ff7d18;
    }
    ::v-deep .btn2 {
      color: #fff;
      border-color: #4e93fb;
      background-color: #4e93fb;
    }
    ::v-deep .btn3 {
      color: #4e93fb;
      border-color: #4e93fb;
      background-color: #fff;
    }
  }
  .color1 {
    color: #d4d4d4;
  }
  .color2 {
    color: #f53939;
  }
  .color3 {
    color: #999;
  }
}
</style>
