<template>
  <div class="upload_video" id="upload_video">
    <el-upload
      class="upload-demo"
      ref="upload"
      action="#"
      :http-request="uploadVideo"
      :file-list="fileList"
      :limit="1"
      :on-remove="handleRemove"
      :on-change="handleChange"
      :auto-upload="false"
    >
      <el-button slot="trigger" size="small" type="primary">选取视频</el-button>
      <el-button style="margin-left: 40px" size="small" type="success" @click="submitUpload"
        >点击上传</el-button
      >
      <el-progress
        class="progress"
        :text-inside="true"
        :stroke-width="18"
        :percentage="progress"
        status="exception"
      ></el-progress>
      <div slot="tip" class="el-upload__tip">只能上传mp4文件，且不超过500M</div>
    </el-upload>
    <video
      v-show="videoURL"
      style="width: 228px; margin-top: 20px; height: 132px"
      :src="videoURL"
      id="video"
      controls
      autoplay
    ></video>
    <img id="video_img" :src="imgBase" style="width: 90px; height: 160px; display: none" />
  </div>
</template>
<script>
import TcVod from 'vod-js-sdk-v6'
import { uploadsign } from '@/api/common'

export default {
  components: {},
  props: {
    videos: {
      type: String
    }
  },
  data() {
    return {
      // 文件列表
      fileList: [],
      // 上传成功后的地址
      videoURL: '',
      // 进度条百分比
      progress: 0,
      // base64图片地址  注：这个是项目需要设置一个默认的视频封面，不需要的忽略就行
      imgBase: '',
      // 上传视频获取成功后拿到的fileID【备用】
      fileId: ''
    }
  },
  watch: {
    videos(newVal) {
      if (newVal) {
        this.videoURL = this.videos
        if (this.videoURL) {
          this.progress = 100
          this.fileList = [
            {
              name: this.videoURL,
              url: this.videoURL
            }
          ]
          console.log('=======1=====')
          console.log(this.fileList)
        }
      }
    }
  },
  created() {},
  methods: {
    // 获取签名  这里的签名请求是由后端提供的，只需要拿到后端给的签名请求即可
    getVodSignature() {
      return uploadsign().then((response) => {
        return response.data.message
      })
    },
    // 文件列表改变时  将文件列表保存到本地
    handleChange(file, fileList) {
      this.fileList = []
      this.progress = 0
      this.fileList = fileList
      console.log(this.fileList)
    },
    // 点击上传时
    submitUpload() {
      if (this.fileList.length < 1) return this.$message.warning('请先选取视频，再进行上传')
      this.uploadVideo()
    },
    // 自定义上传
    uploadVideo(e) {
      //必须以函数的形式返回  sdk参数限制
      const getSignature = async () => {
        const data = await this.getVodSignature()

        return data
      }

      const tcVod = new TcVod({
        getSignature: getSignature // 获取上传签名的函数
      })
      // 获取通过elementui上传到本地的文件  因为参数类型必须为file 不能直接以对象的形式传输
      const mediaFile = this.fileList[0].raw
      const uploader = tcVod.upload({
        mediaFile: mediaFile
      })

      // 监听上传进度
      uploader.on('media_progress', (info) => {
        this.progress = parseInt(info.percent * 100)
      })
      // 上传结束时，将url存到本地
      uploader.done().then((doneResult) => {
        // 保存地址

        // console.log(this.fileId)
        this.fileId = doneResult.fileId
        this.videoURL = doneResult.video.url
        this.$emit('uploadOneImg', this.fileId)
        // 将视频的第一帧保存为封面  不需要封面的可以直接忽略掉以下代码(不生效)
        const canvas = document.createElement('canvas')
        const img = document.getElementById('video_img')
        const video = document.getElementById('video')
        video.setAttribute('crossOrigin', 'anonymous')
        video.currentTime = 1
        canvas.width = video.clientWidth
        canvas.height = video.clientHeight
        video.onloadeddata = (res) => {
          canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
          const dataURL = canvas.toDataURL('image/png')
          img.setAttribute('src', dataURL)
          // 拿到base64的字符串，并保存到本地
          this.imgBase = dataURL.split(',')[1]
        }
      })
    },
    // 点击删除时
    handleRemove(file, fileList) {
      this.progress = 0
      this.fileList = []
      this.videoURL = ''
      this.$emit('removeImg', fileList)
    }

    // handlePictureCardPreview(file) {
    //   // console.log(file)
    //   this.dialogImageUrl = file.url
    //   this.dialogVisible = true
    // },
    // async upload(params) {
    //   const form = new FormData()
    //   form.append('file', params.file)
    //   const { data: res } = await apiUpload(form)

    //   this.fileLists.push({
    //     url: res.data,
    //     uid: params.file.uid
    //   })

    //   this.$emit('uploadOneImg', this.fileLists)
    // },
    // handleRemove(file, fileList) {
    //   this.fileLists.forEach((item, index) => {
    //     if (item.uid === file.uid) {
    //       this.fileLists.splice(index, 1)
    //     }
    //   })

    //   this.$emit('removeImg', fileList)
    // }
  },

  mounted() {}
}
</script>
<style scoped lang="less">
/deep/.el-upload--picture-card {
  width: 438px;
  border-radius: 2px;
  border: none;
  text-align: center;
  line-height: 134px;
  font-size: 14px;
  color: #4e93fb;
  display: inline-block;
  background-color: #f4f4f4;
}
.hidden /deep/.el-upload--picture-card {
  display: none;
}
/deep/.el-upload-list__item {
  width: 438px;
  border: none;
}
/deep/.el-dialog {
  width: 500px;
  height: 500px !important;
}
/deep/.el-dialog__body {
  width: 500px;
  height: 500px !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-dialog__header {
  padding: 0;
}
::v-deep .el-progress-bar {
  padding-right: 50px;
  display: inline-block;
  vertical-align: middle;
  width: 550px;
  margin-right: -55px;
  box-sizing: border-box;
}
.img {
  width: 450px;
  height: 450px;
}
</style>
